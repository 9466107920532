import React, { useContext } from "react";
import { AppContext } from "../contexts";

const ListOrder = () => {
  const context = useContext(AppContext);
  const {
    listIcon,
    listChanger,
    listHandeler,
    activeClass,
    taps,
    activeClassStyle,
  } = context;
  return (
    <div className="list">
      <div className="changeList">
        <i
          className={`fas fa-${listIcon ? "bars" : "list"}`}
          onClick={listChanger}
        ></i>
        <span>change the list's order</span>
      </div>
      {taps.items &&
        taps.items.map(({ id, content }, index) => (
          <button
            key={index}
            onClick={() => {
              listHandeler(content);

              activeClass(index);
            }}
            className={activeClassStyle(index)}
          >
            {content}
          </button>
        ))}
    </div>
  );
};

export default ListOrder;
