export const myProjects = [
  {
    id: 1,
    title: "rabit",
    content:
      "the application is about shorten the links if you have many links that you want shorten them in one link rabit is your first destination . unfortunatly rabit does not have english langauge but i'm working to rebuild it from scratch using react and react native",
    url: "https://rabit-97.web.app/index.html",
    badges: ["html", "css", "javascript", "adobe xd", "firebase", "bootstrap"],
    img: "imgs/rabit.png",
  },
  {
    id: 2,
    title: "the syrian destruction gallery ",
    content:
      "by using this application you can uplaod and watch picture of the syrian tragedey",
    url: "https://the-syrian-destruction.netlify.app/",
    badges: [
      "react",
      "react hooks",
      "context api",
      "materialize css",
      "firebase",
    ],
    img: "imgs/syrian destruction.png",
  },

  {
    id: 5,
    title: "techy store",
    content: "An e-commerce application that buys vintage gadgets ",
    url: "https://techy-store-by-mooder.netlify.app/",
    badges: ["react", "react hooks", "context api", "stripe"],
    img: "imgs/techstore.png",
  },

  {
    id: 6,
    title: "sarahah clone",
    content:
      "A clone of sarahah application with adding all its features and i'm planning to add more ",
    url: "https://sarahah-clone.netlify.app/",
    badges: ["react", "react hooks", "context api", "firebase"],
    img: "imgs/sarahah-clone.png",
  },
  {
    id: 7,
    title: "global studies",
    content: "....",
    url: "https://global-studies.netlify.app/",
    badges: ["html", "css", "javascript", "bootstrap"],
    img: "imgs/global-studies.png",
  },
  {
    id: 9,
    title: "react-tabs-scrollable",
    content:
      "a simple react scrollable tabs with a lot of additional features and with fully supporting of RTL mode",
    url: "https://react-tabs-scrollable.vercel.app/",
    badges: ["react", "npm"],
    img: "imgs/react-scrollable-tabs.png",
  },
  {
    id: 10,
    title: "svelte-tabs-scrollable",
    content:
      "a simple svelte scrollable tabs with a lot of additional features and with fully supporting of RTL mode",
    url: "https://svelte-tabs-scrollable.vercel.app/",
    badges: ["svelte", "npm"],
    img: "imgs/svelte-scrollable-tabs.png",
  },
].reverse();

//  -- __ --
