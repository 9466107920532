import React, { useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../contexts";
const Mode = ({ pos }) => {
  const context = useContext(AppContext);
  const { modeHandeler, mode, colorHandeler } = context;
  return (
    <>
      <ModeWrapper onClick={modeHandeler} pos={pos} mode={mode ? "dark" : null}>
        {mode ? <i className="fa fa-moon"></i> : <i className="fa fa-sun"></i>}
      </ModeWrapper>
      <ColorWrapper onClick={colorHandeler} pos={pos}></ColorWrapper>
    </>
  );
};
const ModeWrapper = styled.div`
  z-index: 1;
  position: ${(props) => props.pos};
  top: 50%;
  right: 10px;
  font-size: 30px;
  background: var(--blue);
  color: var(--white);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: var(--mainTransition);
  :hover {
    background: var(--black);
    color: ${(props) => (props.mode === "dark" ? "#707070" : "var(--white)")};
  }
  @media (max-width: 992px) {
    width: 40px;
    height: 40px;
    font-size: 25px;
  }
`;
const ColorWrapper = styled.div`
  z-index: 1;
  transition: var(--mainTransition);
  position: ${(props) => props.pos};

  top: 57%;
  right: 40px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--blue);
  cursor: pointer;
  :hover {
    background: var(--black);
  }
  @media (max-width: 992px) {
    width: 20px;
    height: 20px;
  }
`;
export default Mode;
