import React from "react";
import styled from "styled-components";

const Title = ({ title, border }) => {
  return (
    <TitleWrapper>
      <h3>{title}</h3>
      <div className="titleBorder"></div>
    </TitleWrapper>
  );
};
const TitleWrapper = styled.div`
  h3 {
    margin-top: 30px;
    font-size: 40px;
    text-transform: uppercase;
    color: var(--black);
    text-align: center;
    font-weight: 200;
  }
  .titleBorder {
    width: 20%;
    height: 3px;
    background: var(--yellow);
    margin: 0 auto 50px;
  }
  @media (max-width: 991.98px) {
    h3 {
      font-size: 30px;
    }
  }
`;
export default Title;
