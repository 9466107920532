import React, { useContext, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import "../App.css";
import Home from "../pages/Home";
import MyWorks from "../pages/MyWorks";
import AboutMe from "../pages/AboutMe";
import Contact from "../pages/Contact";
import MySkills from "../pages/MySkills";
import Default from "../pages/Default";
import NavBar from "./NavBar";
import SideBar from "./SideBar";
// import Loading from "./Loading";
import Mode from "./Mode";
import Modal from "./Modal";
import { AppContext } from "../contexts";
import { createGlobalStyle, ThemeProvider } from "styled-components";
function App() {
  const context = useContext(AppContext);
  const { mode, color } = context;
  const [modal, setModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  function fakeRequest() {
    return new Promise((resolve) => setTimeout(() => resolve(), 500));
  }

  useEffect(() => {
    setTimeout(() => {
      setModal(true);
    }, 3000);
    setTimeout(() => {
      setModal(false);
    }, 10000);
  }, []);

  useEffect(() => {
    fakeRequest().then(() => {
      const el = document.querySelector(".loader-container");
      if (el) {
        el.remove();
        setLoading(!isLoading);
      }
    });
  }, [isLoading]);

  if (isLoading) {
    return null;
  }

  return (
    <ThemeProvider theme={{ mode, color }}>
      <GlobalStyle />
      <div className="container-fluid container-xl">
        {/* {loader && <Loading />} */}

        <NavBar />
        <SideBar />
        <div className="responsive-mode">
          <Mode pos="fixed" />
        </div>
        {modal && <Modal />}
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/works" exact component={MyWorks} />
          <Route path="/about" exact component={AboutMe} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/skills" exact component={MySkills} />
          <Route exact component={Default} />
        </Switch>
      </div>
    </ThemeProvider>
  );
}
const GlobalStyle = createGlobalStyle`

body{
  background: ${(props) =>
    props.theme.mode === true ? "var(--dark)" : "var(--white)"}
}
:root {
  /* --blue : ${(props) =>
    props.theme.color ? "var(--yellow)" : "#74B9FF"}; */
  /* --yellow : ${(props) => (props.theme.color ? "var(--blue)" : "")}; */

  --black : ${(props) =>
    props.theme.mode === true ? "var(--white)" : "#707070"};
    
  --yellow : ${(props) =>
    props.theme.mode === true ? "var(--blue)" : "#FDCB6E"};
 --blue : ${(props) => (props.theme.color === true ? "#FDCB6E" : "#74B9FF")}; 
  --yellow : ${(props) => (props.theme.color === true ? "#74B9FF" : "#FDCB6E")};
}
@media (max-width: 991.98px) {
  .responsive-mode{
    display:none;
  }
}
.container-xl {
    padding: 0 30px;
    max-width: 1600px;
    margin: auto;}
`;
export default App;
