import React from "react";
import { Link } from "react-router-dom";
import Title from "../components/Title";
const Default = () => {
  return (
    <>
      <Title title="404 wrong page !" />
      <Link
        to="/"
        className="text-center d-block m-auto "
        style={{
          color: "var(--yellow) ",
          fontSize: 20,
          textTransform: "uppercase",
          width: "40%",
          padding: 10,
          border: "2px solid var(--yellow)",
        }}
      >
        go back to home
      </Link>
    </>
  );
};

export default Default;
