import React from "react";
import styled from "styled-components";

const Border = ({ auto }) => {
  return <BorderWrapper auto={auto}></BorderWrapper>;
};
const BorderWrapper = styled.div`
  width: 20%;
  height: 3px;
  background: var(--yellow);
  margin: ${(props) => (props.auto ? "auto" : "0")};
`;
export default Border;
