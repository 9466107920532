import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
const Modal = () => {
  return (
    <ModalWrapper>
      <motion.div
        className="modal-card"
        initial={{ y: "-100%" }}
        animate={{ y: 0 }}
      >
        <p>
          From here you can change the color and the color mode of this website
        </p>
      </motion.div>
      <motion.i
        className="fas fa-arrow-down i1"
        initial={{ y: 0 }}
        animate={{ y: 20 }}
        transition={{ yoyo: Infinity }}
      ></motion.i>
      <motion.i
        className="fas fa-arrow-up i2"
        initial={{ y: 0 }}
        animate={{ y: 20 }}
        transition={{ yoyo: Infinity }}
      ></motion.i>
    </ModalWrapper>
  );
};
const ModalWrapper = styled.div`
  position: fixed;
  top: 20%;
  right: 20px;
  z-index: 2;

  /* transition: var(---mainTransition); */
  .modal-card {
    width: 80px;
    background: var(--yellow);
    /* padding: 10px; */
    padding: 10px 5px;
    border-radius: 10px;
    font-size: 12px;
  }
  .i1 {
    position: absolute;
    bottom: -40px;
    right: -40px;

    width: 100%;

    display: block;
    font-size: 50px;
  }
  .i2 {
    position: absolute;
    top: -70px;
    /* right: 10px; */
    margin: auto;
    width: 100%;
    text-align: center;
    display: none;
    font-size: 50px;
  }
  p {
    margin: 0;
  }

  @media (max-width: 992px) {
    top: 22%;
    .modal-card {
      border-radius: 10px;
    }
    .i1 {
      display: none;
    }
    .i2 {
      display: block;
    }
  }
`;
export default Modal;
