// firebase.js
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDhXPwYozCmB92Smwj4KL2adaJUA2f_Tjs",
  authDomain: "aliwi-dev.firebaseapp.com",
  projectId: "aliwi-dev",
  storageBucket: "aliwi-dev.firebasestorage.app",
  messagingSenderId: "168380935893",
  appId: "1:168380935893:web:5f82e635d65e8a00743c5c",
  measurementId: "G-LEL9LHMN78",
  databaseURL:
    "https://aliwi-dev-default-rtdb.europe-west1.firebasedatabase.app/",
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
