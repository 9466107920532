import React from "react";
import styled from "styled-components";
import { aboutMe } from "../data/aboutMe";
import { motion } from "framer-motion";
const AboutMe = () => {
  return (
    <motion.div initial={{ y: "-100%" }} animate={{ y: 0 }}>
      <AboutWrapper>
        {aboutMe &&
          aboutMe.map(({ title, id, content }) => (
            <div key={id}>
              <h2>{title}</h2>
              <p>{content}</p>
              {id === 5 ? (
                <a
                  href="https://my-first-website-mooder.netlify.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  see the website <i className="fas fa-paper-plane"></i>
                </a>
              ) : null}

              <div className="border"></div>
            </div>
          ))}
      </AboutWrapper>
    </motion.div>
  );
};

const AboutWrapper = styled.div`
  padding: 0 0 0 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: auto;
  h2 {
    text-transform: uppercase;
    font-size: 60px;
    font-weight: lighter;
    text-align: center;
    color: var(--black);
    margin-bottom: 40px;
  }
  a {
    color: var(--yellow);
  }
  p {
    color: var(--grey);
    margin-top: 20px;
  }
  .border {
    border: none !important;
    padding-top: 60px;
    /* width: 100%;
    height: 2px !important;
    background: var(--yellow) 2px !important; */
  }
  @media (max-width: 991.98px) {
    padding: 0;
    h2 {
      font-size: 40px;
      text-align: left;
      margin-bottom: 20px;
    }
  }
`;
export default AboutMe;
