import React, { useContext, useEffect } from "react";
import styled from "styled-components";

import { AppContext } from "../contexts";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import Card from "../components/Card";
import ListOrder from "../components/ListOrder";

const MyWorks = () => {
  const context = useContext(AppContext);
  const { icons, setIcons, newProjects } = context;
  useEffect(() => {
    setIcons(true);
  }, [icons, setIcons]);

  return (
    <WorksWrapper>
      <ListOrder />
      <motion.div className="row" initial={{ x: "+100%" }} animate={{ x: 0 }}>
        {newProjects &&
          newProjects.map((item) => <Card item={item} key={item.id} />)}
      </motion.div>
      <div className="footer">
        <Footer pos="relative" />
      </div>
    </WorksWrapper>
  );
};
export default MyWorks;

const WorksWrapper = styled.div`
  padding: 0 0 0 20%;
  width: 95%;
  margin: auto;

  height: 100%;
  .list {
    font-size: 30px;
    color: var(--yellow);
    margin-bottom: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .list span {
    font-size: 20px;
    margin-left: 10px;
  }
  .list i {
    cursor: pointer;
  }
  .list button {
    border: 1.5px solid var(--yellow);
    outline: none;
    /* border: none; */
    background: transparent;
    border-radius: 20px;
    font-size: 16px;
    margin-left: 20px;
    padding: 2px 15px;
    transition: var(--mainTransition);
    color: var(--black);
  }
  .list button:hover {
    border: 1.5px solid var(--blue);
  }
  .project-card {
    border: 1px solid var(--grey);
    width: 85%;

    background: var(--pureWhite);
    margin: 20px 0;
    border-radius: 20px;
    padding: 0 0 10px;
    box-shadow: 0px 1px 10px var(--mainRGBA);
  }
  .col-md-3 .project-card {
    width: 100%;
  }

  .img-container {
    width: 100%;
    overflow: hidden;
    border-radius: 20px;

    cursor: pointer;
  }
  .img-container img {
    width: 100%;
  }
  .img-container .img-border {
    margin-top: 20px;
    width: 100%;
    height: 3px;
    background: var(--black);
  }

  .project-card-tittle {
    color: var(--yellow);
    font-weight: 400;
    padding: 5px 10px;
    margin-top: 10px;
    font-size: 28px;
    text-transform: uppercase;
  }
  .content {
    margin-top: 0px;
    color: var(--grey);
    font-size: 14px;
    padding: 0 10px;
  }
  .url {
    color: var(--grey);

    font-size: 20px;
    display: block;
    text-align: center;
    border-top: 1px solid var(--grey);
    padding-top: 10px;
    transition: var(--mainTransition);
  }
  .url:hover {
    color: var(--grey);
    text-decoration: none;
  }
  .badges {
    margin: 10px 0;
  }
  .badges span {
    /* width: 20px; */
    /* height: 5px; */
    color: #f5f6fa;
    background: var(--yellow);
    margin-left: 10px;
    border-radius: 20px;
    padding: 3px 6px;
    font-size: 12px;
    display: inline-block;
    margin-top: 10px;
  }
  .footer {
    margin-top: 60px;
  }
  .footer span {
    /* margin-top: 60px; */
    text-align: left;
    left: 10px;
    bottom: 20px;
  }
  @media (min-width: 991.98px) {
    .footer {
      display: none;
    }
  }
  @media (max-width: 1199.98px) {
    .project-card-tittle {
      font-size: 18px;
    }
  }
  @media (max-width: 991.98px) {
    padding: 0;
    .project-card {
      width: 100%;
    }
    .project-card-tittle {
      font-size: 20px;
    }
    .changeList span {
      display: none;
    }
    .list button {
      font-size: 14px;
    }
    .content {
      margin-top: 0px;
      color: var(--grey);
      font-size: 14px;
      padding: 0 10px;
    }
    .url {
      color: var(--grey);

      font-size: 14px;
      display: block;
      text-align: center;
      border-top: 1px solid var(--grey);
      padding-top: 10px;
      transition: var(--mainTransition);
    }
  }
  @media (max-width: 767.98px) {
    .list {
      margin-bottom: 0px !important;
    }
    .list button {
      font-size: 10px;
      margin-left: 20px;
      padding: 2px 5px;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 575.98px) {
    .changeList {
      display: none;
    }
  }
  .active {
    border: 1.5px solid var(--blue) !important;
  }
`;
