import React, { useState, useEffect } from "react";
import { myProjects } from "../data/myProjects";
const AppContext = React.createContext();
const AppProvider = (props) => {
  const [openNav, setOpenNav] = useState(false);
  const [icons, setIcons] = useState(false);
  const [mode, setMode] = useState(intialMode);
  const [color, setColor] = useState(intialColor);
  const [counter, setCounter] = useState(0);
  const [list, setList] = useState(6);
  const [smallList, setSmallList] = useState(12);
  const [listIcon, setListIcon] = useState(false);
  const [newProjects, setNewProjects] = useState([...myProjects]);
  const [taps, setTaps] = useState({
    selectedItem: null,
    items: [
      {
        id: 1,
        content: "all",
      },
      {
        id: 2,
        content: "javascript",
      },
      {
        id: 3,

        content: "react",
      },
      {
        id: 7,

        content: "svelte",
      },
      {
        id: 8,
        content: "npm",
      },
      {
        id: 4,

        content: "firebase",
      },
      {
        id: 5,

        content: "adobe xd",
      },
    ],
  });

  const handleOpenNav = (e) => {
    setOpenNav((prevOpenNav) => !prevOpenNav);
  };
  const closeNav = () => {
    setOpenNav(false);
  };
  const modeHandeler = (e) => {
    setMode((prevMode) => !prevMode);
  };
  const colorHandeler = (e) => {
    setColor((prevColor) => !prevColor);
  };

  let lists = [4, 3, 10, 6];
  let listsSmall = [6, 12];
  const listChanger = (e) => {
    setListIcon(true);

    setCounter(counter + 1);
    if (counter < lists.length - 1 || counter < listsSmall.length) {
      setSmallList(listsSmall[counter]);
      setList(lists[counter]);
    } else {
      setCounter(0);
      setList(6);
      // setSmallList(12);
    }
    if (smallList === 6) {
      setSmallList(12);
    } else {
      setSmallList(6);
    }
    console.log(counter);
    if (counter === 3) {
      setListIcon(false);
    } else {
      setListIcon(true);
    }
  };

  const listHandeler = (val) => {
    let filteredProjects = myProjects.filter((item) =>
      item.badges.map((item) => item).includes(val)
    );
    setNewProjects([...filteredProjects]);

    if (val === "all") {
      setNewProjects([...myProjects]);
    }
  };
  const activeClass = (index) => {
    setTaps({ ...taps, selectedItem: taps.items[index] });
  };
  const activeClassStyle = (index) => {
    if (taps.selectedItem === taps.items[index]) {
      return "active";
    } else {
      return null;
    }
  };
  useEffect(() => {
    localStorage.setItem("dark", JSON.stringify(mode));
  }, [mode]);
  function intialMode() {
    let saveMode = JSON.parse(localStorage.getItem("dark"));
    return saveMode || true;
  }
  useEffect(() => {
    localStorage.setItem("yellow", JSON.stringify(color));
  }, [color]);
  function intialColor() {
    let savedColor = JSON.parse(localStorage.getItem("yellow"));
    return savedColor || false;
  }
  return (
    <AppContext.Provider
      value={{
        handleOpenNav,
        modeHandeler,
        closeNav,
        openNav,
        setIcons,
        icons,
        mode,
        color,
        colorHandeler,
        listChanger,
        list,
        smallList,
        listIcon,
        listHandeler,
        newProjects,
        activeClass,
        taps,
        activeClassStyle,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
