const date = new Date();
let myAge = date.getFullYear() - 1997;
export const aboutMe = [
  {
    id: 1,
    title: "who am i ?",
    content: ` My name is Mohammed as you saw in the first page i'm ${myAge} years old , i'm from Syria but currently i live and study in turkey`,
  },
  // {
  //   id: 2,
  //   title: "my study",
  //   content:
  //     "I study electrical and electronic engineering , currently i'm at the 4th and the last year of the collage",
  // },
  {
    id: 3,
    title: "me and programming!",
    content:
      "When i was child i was fascinated by computers, tech and electrical circuits so i knew this is the profession that i want to be in when i grow up. unfortunately i couldn't get into IT collage so i chose electrical engineering and started to learn programming on my own and here i am .....",
  },
  {
    id: 4,
    title: "why web development?",
    content:
      "When i started learning programming i started learning java language because i wanted to learn Android development but i stuck with the massive concepts of java and i was new in that profession and i quit it. after that i watched a course about HTML and CSS and i liked the concept and how you can instantly saw what are you writing in the browser so i knew this what i want to start learning. ",
  },
  {
    id: 5,
    title: "how did i start learning web designing ?",
    content: `First of all i watched a small course about HTML and CSS and i took the basics of it and tried to practice them in real project so i start to build the project that they were building on that course so i can know what is the functionality of each tag and element. then i start to build a personal website by what i learnt and to be honest the website was awful 🤣🤣 i will leave a link to see it down below. after learning html and css i start to learn bootstrap and i really liked the concept of it so i followed a course and i built a website using it. After learning all these teachnologies i needed to add some interactivity to the websites that i was building so i start learning javascript and i was in love with it and i still in love with it, after learning the basics i start learning jquery but i didnt like it at all so i return back to javascript and i started to build projects in it. after that i started learning react and why i chose react because i wanted to learn react native after it so i thought it would be the right choice and it is the right choice , i like it so much i liked how it works and how you can organize your work by using jsx and components .  `,
  },
];
