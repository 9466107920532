import React from "react";
import styled from "styled-components";

const SocialIcon = () => {
  return (
    <Icons>
      <a
        href="https://www.instagram.com/just_mood97/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <i className="fab fa-instagram"></i>
      </a>
      <a
        href="https://twitter.com/aliwi299"
        rel="noopener noreferrer"
        target="_blank"
      >
        <i className="fab fa-twitter"></i>
      </a>
      <a
        href="https://github.com/Mood-al"
        rel="noopener noreferrer"
        target="_blank"
      >
        <i className="fab fa-git"></i>
      </a>
      <a
        href="https://www.linkedin.com/in/mohamad-aliwi-3aa19320a"
        rel="noopener noreferrer"
        target="_blank"
      >
        <i className="fab fa-linkedin"></i>
      </a>
    </Icons>
  );
};
const Icons = styled.div`
  a {
    color: var(--black);
    font-size: 24px;
    margin-left: 15px;
  }
  a:hover {
    color: var(--grey);
  }
`;
export default SocialIcon;
