import React, { useContext, useEffect } from "react";
import SocialIcon from "../components/SocialIcon";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { AppContext } from "../contexts";
import { motion } from "framer-motion";
const Home = () => {
  const context = useContext(AppContext);
  const { icons, setIcons } = context;
  useEffect(() => {
    setIcons(false);
  }, [icons, setIcons]);
  return (
    <motion.div initial={{ y: "-100%" }} animate={{ y: 0 }}>
      <HomeWrapper>
        <h1>mohamad aliwi</h1>
        <p>hi, my name is Mohamad and I code and develop web sites.</p>
        <Link to="/skills" className="skillsBtn">
          see my skills
        </Link>

        <div className="icons-section">
          <SocialIcon />
        </div>
      </HomeWrapper>
    </motion.div>
  );
};
const HomeWrapper = styled.div`
  padding: 0 0 0 20%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  max-width: 70%;

  color: var(--black);

  h1 {
    font-weight: 400;
    font-size: 100px;
  }
  p {
    color: var(--grey);
    text-align: left;
    font-size: 18px;
    width: 80%;
    margin-right: auto;
    /* padding-right: 300px; */
    letter-spacing: 2px;
  }
  .skillsBtn {
    display: block;
    flex-wrap: wrap;
    background: var(--yellow);
    border-radius: 50px;
    color: var(--white);
    /* width: 0%; */
    padding: 10px 40px;
    text-align: center;
    margin: auto;
    margin-top: 50px;
    transition: var(--mainTransition);
  }
  .skillsBtn:hover {
    text-decoration: none;
    color: var(--black);
  }
  .icons-section {
    margin-top: 80px;
    margin-left: auto;
  }
  @media (max-width: 991.98px) {
    padding-left: 0;
    margin-top: 80px;
    /* h1 {
      font-size: 50px !important;
    } */
    p {
      color: var(--grey);
      text-align: left;
      font-size: 12px;
      width: 100%;
    }
    .skillsBtn {
      margin-top: 90px;
    }
    .icons-section {
      margin: 80px auto 0;
    }
  }
  @media (max-width: 767.98px) {
    max-width: 80%;
    h1 {
      font-size: 60px !important;
    }
    p {
      text-align: left;
      margin-left: auto;
      margin-top: 20px;
      width: 100%;
    }
  }
  @media (max-width: 575.98px) {
    width: 100%;
    margin: 80px auto;

    h1 {
      font-size: 55px !important;
      width: 100%;
      margin: auto;
    }
    p {
      /* text-align: left; */
      /* margin-left: auto; */
      margin-top: 20px;
      width: 100%;
    }
  }
  @media (max-width: 375px) {
    width: 100%;
    margin: 80px auto;

    h1 {
      font-size: 45px !important;
      width: 100%;
      margin: auto;
    }
    p {
      /* text-align: left; */
      /* margin-left: auto; */
      margin-top: 20px;
      width: 100%;
    }
  }
`;
export default Home;
