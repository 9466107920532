import React, { useState } from "react";
import styled from "styled-components";
import Title from "../components/Title";
import Border from "../components/Border";
import { motion } from "framer-motion";
import emailjs from "emailjs-com";
const Contact = () => {
  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState(false);

  const [values, setValues] = useState({
    name: "",
    lastname: "",
    email: "",
    message: "",
  });
  function sendEmail(e) {
    e.preventDefault();

    // setErr(true);
    if (
      values.name === "" ||
      values.lastname === "" ||
      values.email === "" ||
      values.message === ""
    ) {
      setErr(true);
    } else {
      setSuccess(true);
      emailjs
        .sendForm(
          "gmail",
          "mooder_contact_form",
          e.target,
          "user_v7SqmMwfDaPld4rE1SH3I"
        )
        .then(
          (result) => {},
          (error) => {
            console.log(error.text);
          }
        );
      setValues({
        name: "",
        lastname: "",
        email: "",
        message: "",
      });
    }
  }
  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  const handleErr = (e) => {
    setTimeout(() => {
      setErr(false);
      setSuccess(false);
    }, 3000);
  };

  return (
    <motion.div initial={{ y: "-100%" }} animate={{ y: 0 }}>
      <ContactWrapper>
        <Title title="let's get in touch" />
        <form className="row" onSubmit={sendEmail}>
          <motion.div className="col-md-6" whileHover={{ scale: 1.1 }}>
            <input
              type="text"
              id="input1"
              placeholder="name"
              name="name"
              onChange={handleChange}
              value={values.name}
            />
          </motion.div>

          <motion.div className="col-md-6" whileHover={{ scale: 1.1 }}>
            <input
              type="text"
              id="input2"
              placeholder="last name"
              name="lastname"
              onChange={handleChange}
              value={values.lastname}
            />
          </motion.div>
          <motion.div className="col-md-12" whileHover={{ scale: 1.1 }}>
            <input
              type="email"
              id="input3"
              placeholder="email"
              name="email"
              onChange={handleChange}
              value={values.email}
            />
          </motion.div>
          <motion.div className="col-md-12" whileHover={{ scale: 1.1 }}>
            <textarea
              name="message"
              cols="30"
              rows="10"
              placeholder="what do want to say ?"
              onChange={handleChange}
              value={values.message}
            ></textarea>
          </motion.div>
          <h4 className="text-center mt-5">
            {err ? "you must fill all the fields" : null}
            {success ? "you have sent the email successfully" : null}
          </h4>
          <div className="col-md-12">
            {/* <input type="submit" className="submit" /> */}
            <button onClick={handleErr}>
              send <i className="fa fa-paper-plane"></i>
            </button>
          </div>
        </form>

        <div className="col-md-12 or">
          <h3> or </h3>
          <Border auto="auto" />
          <p>
            you can contact with me on gmail : {"   "}
            <span>aliwi299@gmail</span> and on telegram{" "}
            <span>@justNothing</span>
          </p>
        </div>
      </ContactWrapper>
    </motion.div>
  );
};
const ContactWrapper = styled.div`
  padding: 0 0 0 20%;
  width: 80%;
  margin: auto;
  h4 {
    color: var(--yellow);
    font-size: 30px;
    font-weight: 200;
    text-align: center;
    margin: auto;
  }
  input {
    padding: 20px;
    width: 90%;
    height: 30px;
    border: 2px solid var(--yellow);
    margin: 20px 0;
    display: block;
    border-radius: 10px;
    outline: none;
    transition: var(--mainTransition);
  }
  input:focus {
    border: 2px solid var(--blue);
  }
  textarea:focus {
    border: 2px solid var(--blue);
  }
  #input2 {
    margin-right: auto !important;
    /* margin-top: 30px; */
  }
  #input1 {
    margin-left: auto !important;
  }
  #input3 {
    margin: 0px auto 20px !important;
  }
  textarea {
    outline: none;
    padding: 20px;
    width: 90%;
    /* height: 30px; */
    border: 2px solid var(--yellow);
    margin: auto;
    display: block;
    border-radius: 10px;
  }
  button {
    /* padding: 20px; */
    width: 20%;
    height: 40px;
    border: 2px solid var(--yellow);
    margin: 20px auto 0;
    display: block;
    border-radius: 10px;
    transition: var(--mainTransition);
    background: var(--pureWhite);
    outline: none;
  }
  button:hover {
    border: 2px solid var(--blue);
  }
  .or {
    margin: 60px 0;
  }
  h3 {
    font-size: 60px;
    font-weight: 200;
    text-align: center;
    color: var(--black);
  }
  p {
    text-align: center;
    margin-top: 30px;
    font-size: 20px;

    color: var(--grey);
  }
  p span {
    color: var(--yellow);
  }
  @media (max-width: 991.98px) {
    padding: 0;
    width: 90%;
    #input2 {
      /* margin-right: 0 !important; */
      /* margin-top: 30px; */
      margin: 20px auto;
    }
    #input1 {
      /* margin-left: 0 !important; */
      margin: auto;
    }
    textarea {
      margin: auto;
    }
    button {
      /* padding: 20px; */
      width: 50%;
    }
    p {
      font-size: 18px;
    }
  }
`;
export default Contact;
