import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { db } from "../firebase"; // adjust path as needed
import { ref, onValue, increment, set } from "firebase/database";

const Footer = ({ pos }) => {
  const date = new Date();
  const [visitCount, setVisitCount] = useState(0);

  useEffect(() => {
    // Reference to our visitors count in Firebase
    const visitorCountRef = ref(db, "visitors");

    // Check if this is a new session
    const hasVisited = sessionStorage.getItem("hasVisited");

    if (!hasVisited) {
      // Increment the count for new visitors
      set(visitorCountRef, increment(1));
      sessionStorage.setItem("hasVisited", "true");
    }

    // Listen for changes to the visitor count
    const unsubscribe = onValue(visitorCountRef, (snapshot) => {
      const count = snapshot.val() || 0;
      setVisitCount(count);
    });

    // Cleanup subscription
    return () => unsubscribe();
  }, []);

  return (
    <Rights pos={pos}>
      all rights reserved {date.getFullYear()}
      <p>Number of visits: {visitCount.toLocaleString()}</p>
    </Rights>
  );
};

const Rights = styled.span`
  font-size: 12px;
  position: ${(props) => props.pos};
  bottom: 40px;
  left: 40px;
  color: var(--grey);
`;

export default Footer;
