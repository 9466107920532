import React, { useContext } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { AppContext } from "../contexts";
import Footer from "../components/Footer";

const SideBar = () => {
  const context = useContext(AppContext);
  const { openNav, closeNav, mode } = context;
  return (
    <SideNav openNav={openNav} mode={mode ? "dark" : null}>
      <ul>
        <li>
          <NavLink
            to="/"
            className="link"
            exact
            activeClassName="active"
            onClick={closeNav}
          >
            home
            <div className="border1"></div>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="works"
            className="link"
            exact
            activeClassName="active"
            onClick={closeNav}
          >
            my works
          </NavLink>
          <div className="border1"></div>
        </li>
        <li>
          <NavLink to="about" className="link" onClick={closeNav}>
            about me
          </NavLink>
          <div className="border1"></div>
        </li>
        <li>
          <NavLink to="contact" className="link" onClick={closeNav}>
            contact me
          </NavLink>
          <div className="border1"></div>
        </li>
        <li>
          <NavLink to="skills" className="link" onClick={closeNav}>
            my skills
          </NavLink>
          <div className="border1"></div>
        </li>
      </ul>
      <div className="sideBarFooter">
        <Footer pos="fixed" />
      </div>
    </SideNav>
  );
};

const SideNav = styled.div`
  /* width: 20%; */
  position: fixed;
  padding: 20px;
  transition: var(--mainTransition);
  @media (max-width: 991.98px) {
    padding-top: 50px;
    background: ${(props) =>
      props.mode === "dark" ? "var(--dark)" : "var(--white)"};
    transform: ${(props) =>
      props.openNav ? "translateY(0)" : "translateY(-200%)"};
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 3;
    /* padding: 0; */
  }

  ul {
    list-style: none;
    transition: var(--mainTransition);
  }
  ul li {
    margin-top: 50px;
    position: relative;
  }
  ul .link {
    font-size: 1.3rem;
    text-transform: uppercase;
    color: var(--black);
    display: block;
    transition: var(--mainTransition);
  }
  ul .link:hover {
    text-decoration: none;
    padding-left: 10px;
  }
  .border1 {
    height: 3px !important;
    width: 90px;

    background: var(--yellow);
    position: absolute;
    bottom: 0;
    left: 40px;
    transition: var(--mainTransition);
  }
  li:hover .border1 {
    background: var(--blue);
  }
  .active {
    /* color: var(--blue) !important; */
    background: var(--yellow);
    border-radius: 5px;
    width: 100%;
  }
  .sideBarFooter {
    /* background: red;
    width: 30px;
    height: 50px; */
  }

  @media (max-width: 991.98px) {
    ul {
      /* padding: 30px; */
    }
    ul li {
      margin-top: 30px;
    }
    ul .link {
      font-size: 1rem;
      text-transform: uppercase;
      /* color: var(--black); */
      display: block;
      transition: var(--mainTransition);
    }
    .active {
      width: 95%;
    }
    ul .link:hover {
      text-decoration: none;
      padding-left: 10px;
    }
  }
`;
export default SideBar;
