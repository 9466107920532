import React from "react";
import styled from "styled-components";

import { mySkills } from "../data/mySkills";
import Title from "../components/Title";
import Border from "../components/Border";
import { motion } from "framer-motion";
const MySkills = () => {
  return (
    <SkillsWrapper>
      <motion.div className="row" initial={{ y: "-100%" }} animate={{ y: 0 }}>
        {mySkills &&
          mySkills.map(({ myStudy, id, title, skills }) => (
            <div key={id} className="col-md-6">
              <Title title={title} />
              <p>{myStudy}</p>
              <ul className="row">
                {skills &&
                  skills.map((skill) => (
                    <li key={skill} className="col-6 col-md-6 ">
                      {skill}
                      <Border />
                    </li>
                  ))}
              </ul>
            </div>
          ))}

        <div className="col-md-10">
          <Title title="what i used to build this website ?" />
          <p>
            First of all i designed it using <span>adobe xd</span> then i start
            building it using <span>React and react hooks</span> , also i used{" "}
            <span>context api </span> as a state manegment{" "}
            <span>bootstrap</span> for grid system and{" "}
            <span>styled component</span> for styling
          </p>
        </div>
      </motion.div>
    </SkillsWrapper>
  );
};
const SkillsWrapper = styled.div`
  padding: 0 0 0 20%;
  p {
    color: var(--grey);
  }
  ul {
    list-style: none;
    width: 70%;
    margin: auto;
  }
  li {
    margin: 20px auto;
    text-transform: uppercase;
    color: var(--black);
  }
  p span {
    color: var(--yellow);
    text-transform: uppercase;
  }

  @media (max-width: 991.98px) {
    padding: 0;
  }
`;

export default MySkills;
