import React, { useContext } from "react";
import { motion } from "framer-motion";
import { AppContext } from "../contexts";

const Card = ({ item }) => {
  const context = useContext(AppContext);
  const { list, smallList } = context;
  const { title, content, url, id, img, badges } = item;
  return (
    <div className={`col-md-${list} col-${smallList} m-auto`} key={id}>
      <motion.div
        className="project-card"
        whileHover={{ scale: 1.09 }}
        transition={{ duration: 0.2 }}
        layout
      >
        <motion.div className="img-container" whileHover={{ scale: 1.3 }}>
          <img src={`${img}`} alt={title} loading="lazy" />
        </motion.div>

        <h4 className="project-card-tittle">{title}</h4>
        <p className="content">{content}</p>
        <div className="badges">
          {badges.map((badge) => (
            <span key={badge}>{badge}</span>
          ))}
        </div>
        <div className="see-project">
          <a
            href={url}
            className="url"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fas fa-eye"></i> see project
          </a>
        </div>
      </motion.div>
    </div>
  );
};

export default Card;
