export const mySkills = [
  // {
  //   id: 1,
  //   title: "my study",
  //   myStudy:
  //     "I study electrical and electronic engineering , currently i'm at the 4th and the last year of the collage",
  // },
  {
    id: 2,
    title: "my skills",
    skills: [
      "html",
      "css",
      "javascript",
      "bootstrap",
      "materialize css",
      "react js",
      "next js",
      "svelte",
      "php laravel",
      "firebase",
      "adobe xd",
    ],
  },
];
